import { Faker, de } from '@faker-js/faker';

export const propertyManagementGenerateMockData = ({ object, numberOfRows }: any) => {
    let data: any = {
        owner: [],
        property: [],
        rentalUnit: [],
        tenant: [],
        rentalSecurityDepositAccount: [],
        costStructure: [],
        serviceProvider: [],
        costType: [],
        administrativeCost: [],
        homeownersAssociationCostStructure: [],
    };

    if (object === 'owner') {
        for (let i = 0; i < numberOfRows; i++) {
            const customFaker = new Faker({ locale: [de] });
            let firstName = customFaker.person.firstName();
            let lastName = customFaker.person.lastName();
            let fullName = firstName + ' ' + lastName;
            data.owner.push({
                id: customFaker.string.uuid(),
                fullName: fullName,
                firstName: firstName,
                lastName: lastName,
                telephoneNumber: customFaker.phone.number('+49 ### #######'),
                email: customFaker.internet.email(),
                street: customFaker.location.street(),
                streetNumber: customFaker.location.buildingNumber(),
                postcode: customFaker.location.zipCode(),
                city: customFaker.location.city(),
                contractFrom: customFaker.date.recent(),
                contractTo: customFaker.date.future(),
                iban: customFaker.finance.iban({ countryCode: 'DE', formatted: true }),
                bic: customFaker.finance.bic(),
            });
        }
        return data;
    }

    if (object === 'property') {
        data.owner = propertyManagementGenerateMockData({ object: 'owner', numberOfRows: Math.ceil(numberOfRows / 4) })['owner'];
        for (let i = 0; i < numberOfRows; i++) {
            const customFaker = new Faker({ locale: [de] });
            const street = customFaker.location.street();
            const streetNumber = customFaker.location.buildingNumber();
            const postcode = customFaker.location.zipCode();
            const city = customFaker.location.city();
            data.property.push({
                id: customFaker.string.uuid(),
                owner: data.owner[Math.floor(Math.random() * data.owner.length)],
                name: `${street} ${streetNumber}, ${postcode} ${city}`,
                street,
                streetNumber,
                postcode,
                city,
                parcel: customFaker.helpers.fromRegExp(/[0-9]{1}-[0-9]{4}/),
                totalArea: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/))),
                numberRentalUnits: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}/)),
                propertyStatus: customFaker.helpers.arrayElement(['INAKTIV', 'BKA-RELEVANT']),
            });
        }

        return data;
    }

    if (object === 'rentalUnit') {
        data = propertyManagementGenerateMockData({ object: 'property', numberOfRows: Math.ceil(numberOfRows / 8) });

        for (let i = 0; i < numberOfRows; i++) {
            const property = data.property[Math.floor(Math.random() * data.property.length)];
            const customFaker = new Faker({ locale: [de] });
            let firstName = customFaker.person.firstName();
            let lastName = customFaker.person.lastName();
            let fullName = firstName + ' ' + lastName;
            let appartmentNumber = customFaker.helpers.fromRegExp(/[0-9]{3}/);

            data.rentalUnit.push({
                id: customFaker.string.uuid(),
                owner: property.owner,
                property,
                description: `ME - ${property.street} ${property.streetNumber} - W${appartmentNumber}`,
                location: customFaker.helpers.arrayElement(['EG', '1. OG', '2. OG', '3. OG', 'UG']),
                type: Math.random() < 0.95 ? 'Wohnung' : 'Lager',
                area: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/))),
                appartmentNumber,
            });
        }

        return data;
    }

    if (object === 'tenant') {
        data = propertyManagementGenerateMockData({ object: 'rentalUnit', numberOfRows: Math.ceil(numberOfRows / 8) });

        for (let i = 0; i < numberOfRows; i++) {
            const customFaker = new Faker({ locale: [de] });
            const rentalUnit = data.rentalUnit[Math.floor(Math.random() * data.rentalUnit.length)];
            let sex = customFaker.helpers.arrayElement(['male', 'female']);
            let firstName = customFaker.person.firstName(sex as 'male' | 'female');
            let lastName = customFaker.person.lastName(sex as 'male' | 'female');
            let fullName = firstName + ' ' + lastName;

            data.tenant.push({
                id: customFaker.string.uuid(),
                owner: rentalUnit.property.owner,
                property: rentalUnit.property,
                rentalUnit,
                fullName: fullName,
                salutation: sex === 'male' ? 'Herr' : 'Frau',
                title: Math.random() < 0.95 ? '' : 'Dr.',
                firstName: firstName,
                lastName: lastName,
                telephoneNumber: customFaker.phone.number('+49 ### #######'),
                street: customFaker.location.street(),
                streetNumber: customFaker.location.buildingNumber(),
                postcode: customFaker.location.zipCode(),
                city: customFaker.location.city(),
                contractFrom: customFaker.date.recent(),
                contractTo: customFaker.date.future(),
                iban: customFaker.finance.iban({ countryCode: 'DE', formatted: true }),
                bic: customFaker.finance.bic(),
                type_of_use: customFaker.helpers.arrayElement(['Wohnung', 'Lager']),
            });
        }

        return data;
    }

    if (object === 'rentalSecurityDepositAccount') {
        data = propertyManagementGenerateMockData({ object: 'tenant', numberOfRows: Math.ceil(numberOfRows) });

        for (let i = 0; i < numberOfRows; i++) {
            const customFaker = new Faker({ locale: [de] });
            const tenant = data.tenant[Math.floor(Math.random() * data.tenant.length)];

            data.rentalSecurityDepositAccount.push({
                id: customFaker.string.uuid(),
                owner: tenant.rentalUnit.property.owner,
                property: tenant.rentalUnit.property,
                rentalUnit: tenant.rentalUnit,
                tenant,
                iban: customFaker.finance.iban({ countryCode: 'DE', formatted: true }),
                bic: customFaker.finance.bic(),
                accountType: customFaker.helpers.arrayElement(['Verpfändung', 'Sparbuch', 'Mietbürgschaft', 'Kautionskonto']),
                status: customFaker.helpers.arrayElement(['PASSIV', 'AKTIV']),
                amount: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                lastChange: customFaker.date.recent(),
            });
        }

        return data;
    }

    if (object === 'costStructure') {
        data = propertyManagementGenerateMockData({ object: 'tenant', numberOfRows: Math.ceil(numberOfRows) });

        for (let i = 0; i < numberOfRows; i++) {
            const customFaker = new Faker({ locale: [de] });
            const tenant = data.tenant[i];

            data.costStructure.push({
                id: customFaker.string.uuid(),
                owner: tenant.rentalUnit.property.owner,
                property: tenant.rentalUnit.property,
                rentalUnit: tenant.rentalUnit,
                tenant,
                rentFrom: customFaker.date.recent(),
                rentTo: customFaker.date.future(),
                coldRent: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                operatingCost: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                heatingCost: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                carMonthly: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                gardenMonthly: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                electricity: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                antenna: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                vat: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                carAnnual: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                gardenAnnual: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
            });
        }

        return data;
    }

    if (object === 'serviceProvider') {
        for (let i = 0; i < numberOfRows; i++) {
            const customFaker = new Faker({ locale: [de] });
            let firstName = customFaker.person.firstName();
            let lastName = customFaker.person.lastName();
            let fullName = firstName + ' ' + lastName;
            data.serviceProvider.push({
                id: customFaker.string.uuid(),
                company: customFaker.company.name(),
                fullName: fullName,
                firstName: firstName,
                lastName: lastName,
                telephoneNumber: customFaker.phone.number('+49 ### #######'),
                email: customFaker.internet.email(),
                street: customFaker.location.street(),
                streetNumber: customFaker.location.buildingNumber(),
                postcode: customFaker.location.zipCode(),
                city: customFaker.location.city(),
                contractFrom: customFaker.date.recent(),
                contractTo: customFaker.date.future(),
                iban: customFaker.finance.iban({ countryCode: 'DE', formatted: true }),
                bic: customFaker.finance.bic(),
            });
        }
        return data;
    }

    if (object === 'costType') {
        for (let i = 0; i < numberOfRows; i++) {
            const customFaker = new Faker({ locale: [de] });

            data.costType.push({
                id: customFaker.string.uuid(),
                shortName: customFaker.helpers.fromRegExp(/[A-Z]{2,2}/),
                longName: customFaker.helpers.fromRegExp(/[A-Z][a-z]{7,9}/),
                income: Math.random() < 0.5,
                outcome: Math.random() < 0.5,
                tenantBinding: Math.random() < 0.5,
                serviceProvider: Math.random() < 0.5,
                transferable: Math.random() < 0.5,
                objectTenantBinding: Math.random() < 0.5,
                category: customFaker.helpers.arrayElement(['Mietzahlungen / Betriebskostenvorauszahlung / Sonstige Einnahmen', 'Sonstige Ausgaben', 'Verwaltungskosten']),
                bkaCostType: customFaker.helpers.arrayElement(['VERWALTUNGKOSTEN', 'SONSTIGE', '']),
            });
        }
        return data;
    }

    if (object === 'administrativeCost') {
        data = propertyManagementGenerateMockData({ object: 'property', numberOfRows: Math.ceil(numberOfRows / 8) });

        for (let i = 0; i < numberOfRows; i++) {
            const property = data.property[Math.floor(Math.random() * data.property.length)];
            const customFaker = new Faker({ locale: [de] });

            data.administrativeCost.push({
                id: customFaker.string.uuid(),
                owner: property.owner,
                property,
                fromDate: customFaker.date.recent(),
                toDate: customFaker.date.future(),
                amountResidentialUnits: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}/)),
                costResidentialUnit: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                amountBusinessUnits: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}/)),
                costBusinessUnit: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                amountOtherUnits: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}/)),
                costOtherUnit: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
                amountExpenses: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}/)),
                costExpenses: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
            });
        }

        return data;
    }

    if (object === 'homeownersAssociationCostStructure') {
        data = propertyManagementGenerateMockData({ object: 'rentalUnit', numberOfRows: Math.ceil(numberOfRows / 8) });

        for (let i = 0; i < numberOfRows; i++) {
            const customFaker = new Faker({ locale: [de] });
            const rentalUnit = data.rentalUnit[Math.floor(Math.random() * data.rentalUnit.length)];
            const housingContribution = parseFloat(customFaker.helpers.fromRegExp(/[0-9]{3}.[0-9]{2}/));
            const maintenanceReserve = housingContribution * 0.1;
            const sum = housingContribution + maintenanceReserve;

            data.homeownersAssociationCostStructure.push({
                id: customFaker.string.uuid(),
                owner: rentalUnit.property.owner,
                property: rentalUnit.property,
                rentalUnit,
                fromDate: customFaker.date.recent(),
                toDate: customFaker.date.future(),
                housingContribution: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(housingContribution),
                maintenanceReserve: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(maintenanceReserve),
                sum: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(sum),
                iban: customFaker.finance.iban({ countryCode: 'DE', formatted: true }),
                bic: customFaker.finance.bic(),
            });
        }

        return data;
    }

    return data;
};
