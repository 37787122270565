import { Route, Link, useLocation } from 'react-router-dom';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './MainWrapperStyle.module.css';
import Avatar from 'react-avatar';
import './MainWrapper.css';
import { useTranslation } from 'react-i18next';
import { useActions } from '../../../../../hooks/useActions';
import OrganizationApp from '../../../central-configuration/components/OrganizationApp/OrganizationApp';
import ServiceApp from '../../../central-configuration/components/ServiceApp/ServiceApp';
import ErrorAlerts from './MainWindow/ErrorAlerts/ErrorAlerts';
import LanguagePicker from './MainWindow/LanguagePicker/LanguagePicker';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import UserApp from '../../../organization-configuration/components/UserApp/UserApp';
import OrganizationPicker from './MainWindow/OrganizationPicker/OrganizationPicker';
import fireToast from '../../methods/fireToast';
import showConfirmPopup from '../../methods/showConfirmPopup';
import UserEigentuemerApp from '../../../../ri/property-management/components/UserEigentuemerApp/UserEigentuemerApp';
import TransactionApp from '../../../../ri/property-management/components/TransactionApp/TransactionApp';
import DataRefreshApp from '../../../../ri/property-management/components/DataRefreshApp/DataRefreshApp';
import ExcelDownloadApp from '../../../../ri/property-management/components/ExcelDownloadApp/ExcelDownloadApp';
import ReportApp from '../../../../ri/property-management/components/ReportApp/ReportApp';
import ConfigurationApp from '../../../../ri/property-management/components/ConfigurationApp/ConfigurationApp';
import WegApp from '../../../../ri/property-management/components/WegApp/WegApp';
import ProfileApp from '../../../organization-configuration/components/ProfileApp/ProfileApp';
import RIRealEstateConfigurationApp from '../../../../ri/real-estate/components/ConfigurationApp/RIRealEstateConfigurationApp';
import RIRealEstateObjectsApp from '../../../../ri/real-estate/components/ObjectApps/RIRealEstateObjectsApp';
import RIRealEstateObjectsDetailApp from '../../../../ri/real-estate/components/ObjectApps/RIRealEstateObjectsDetailApp';
import RIRealEstateHomepageApp from '../../../../ri/real-estate/components/HomepageApp/RIRealEstateHomepageApp';
import BelegeApp from '../../../../ri/property-management/components/BelegeApp/BelegeApp';
import BenitRealEstateObjectsApp from '../../../real-estate/components/ObjectsApp/ObjectsApp';
import AccountingConfigurationApp from '../../../accounting/components/ConfigurationApp/AccountingConfigurationApp';
import InvoiceApp from '../../../accounting/components/InvoiceApp/InvoiceApp';
import InvoiceDetailApp from '../../../accounting/components/InvoiceApp/InvoiceDetailApp';
import ExpensesApp from '../../../accounting/components/ExpensesApp/ExpensesApp';
import ExpensesDetailApp from '../../../accounting/components/ExpensesApp/ExpensesDetailApp';
import AnalyticsApp from '../../../../ri/property-management/components/AnalyticsApp/AnalyticsApp';
import AssistantApp from '../../../assistant/components/AssistantApp/AssistantApp';
import SISAssistantApp from '../../../care/components/AssistantApp/AssistantApp';
import { propertyManagementMasterDataAppsConfiguration } from '../../../property-management/components/master-data-apps/propertyManagementMasterDataAppsConfiguration';
import { MasterDataApp } from '../../../../../components/MasterData/MasterDataApp';
import { MasterDataDetailApp } from '../../../../../components/MasterData/MasterDataDetailApp';
import { propertyManagementGenerateMockData } from '../../../property-management/components/master-data-apps/mock/propertyManagementGenerateMockData';
import { accountingMasterDataAppsConfiguration } from '../../../accounting/components/master-data/accountingMasterDataAppsConfiguration';
import { accountingGenerateMockData } from '../../../accounting/components/master-data/mock/accountingGenerateMockData';
import TimeTrackingApp from '../../../accounting/components/TimeTrackingApp/TimeTrackingApp';

const MainWrapper = ({ devModeOn }: any) => {
    const history = useHistory();
    const [logoutWarningTimeoutId, setLogoutWarningTimeoutId] = useState(0);
    const [logoutTimeoutId, setLogoutTimeoutId] = useState(0);
    const [appSpecificNavbarComponents, setAppSpecificNavbarComponents] = useState<null | any[]>(null);
    const { mlfv } = useMultiLanguageFieldValue();
    const { userLanguages, firstName, lastName, applications, organizations, logonExpirationTime, startApplication, userDefaultLanguage, loginRedirect } = useTypedSelector(
        (state) => state.benitAuthReducer
    );
    const { helpActivated } = useTypedSelector((state) => state.benitCentralUIReducer);
    const { languageKey, languagesLoaded } = useTypedSelector((state) => state.benitCentralConfigurationReducer);
    const { benit_auth_logoutUser, benit_auth_getCurrentUser, benit_central_configuration_change_language, benit_central_configuration_language_changed, benit_central_set_help_activated } =
        useActions();

    function loadTree() {
        const trees: any = $('[data-widget="treeview"]');
        trees.Treeview('init');
    }

    const { t, i18n } = useTranslation(['translation', 'benit-central-configuration', 'general']);

    const closeSidebar = () => {
        if (document.body.classList.contains('sidebar-open')) {
            document.body.classList.remove('sidebar-open');
            document.body.classList.add('sidebar-closed');
            document.body.classList.add('sidebar-collapse');
        }
    };

    useEffect(() => {
        loadTree();
    }, []);

    useEffect(() => {
        if (applications && applications.length == 1) {
            document!.querySelector('body')!.classList.remove('sidebar-collapse');
        }
        loadTree();
    }, [applications]);

    useEffect(() => {
        const cSidebar = document.getElementsByClassName('control-sidebar')[0];
        if (cSidebar) {
            if (helpActivated) {
                cSidebar.classList.remove('d-none');
                cSidebar.classList.add('d-block');
            } else {
                cSidebar.classList.remove('d-block');
                cSidebar.classList.add('d-none');
            }
        }
    }, [helpActivated]);

    useEffect(() => {
        const isTokenRoute = window.location.pathname.includes('/benit/auth/set-password/') && loginRedirect;
        if (startApplication && typeof startApplication !== 'string' && (window.location.pathname === '/' || isTokenRoute)) {
            //history.push(startApplication.path);
            history.replace(startApplication.path);
        }
    }, [startApplication]);

    useEffect(() => {
        if (userDefaultLanguage) {
            benit_central_configuration_change_language(userDefaultLanguage);
        }
    }, [userDefaultLanguage]);

    useEffect(() => {
        if (languageKey) {
            i18n.changeLanguage(languageKey, benit_central_configuration_language_changed);
            document.documentElement.lang = languageKey;
        }
    }, [languageKey]);

    useEffect(() => {
        if (logonExpirationTime) {
            const now = new Date();
            window.clearTimeout(logoutWarningTimeoutId);
            window.clearTimeout(logoutTimeoutId);
            const millisTillLogonExpirationTimeWarning = logonExpirationTime * 1000 - now.getTime() - 30000;
            const tmpWarningTimeoutId = window.setTimeout(function () {
                showConfirmPopup({
                    title: t('general:logout_warning'),
                    text: t('general:logout_warning_text'),
                    confirmButtonText: t('general:logout_warning_refresh'),
                    confirmButtonColor: '#007bff',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmedFunction: () => {
                        benit_auth_getCurrentUser();
                    },
                });
            }, millisTillLogonExpirationTimeWarning);
            setLogoutWarningTimeoutId(tmpWarningTimeoutId);

            const millisTillLogonExpirationTime = logonExpirationTime * 1000 - now.getTime();
            const tmpTimeoutId = window.setTimeout(function () {
                showConfirmPopup({
                    title: t('general:logout_information'),
                    text: t('general:logout_information_text'),
                    confirmButtonText: t('general:logout_information_go_to_login'),
                    confirmButtonColor: '#007bff',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmedFunction: () => {
                        benit_auth_logoutUser();
                    },
                });
            }, millisTillLogonExpirationTime);
            setLogoutTimeoutId(tmpTimeoutId);
        }
    }, [logonExpirationTime]);

    const location = useLocation();

    const [currentApplicationName, setCurrentApplicationName] = useState('');

    useEffect(() => {
        if (location.pathname === '/benit/profile') {
            const translatedName = mlfv({ de: 'Profil', en: 'Profile' }) || '';
            setCurrentApplicationName(translatedName);
        } else {
            if (location && applications) {
                for (const application of applications) {
                    if (location.pathname.startsWith(application.path)) {
                        const translatedName = mlfv(application.name) || '';
                        setCurrentApplicationName(translatedName);
                    }
                }
            }
        }
    }, [location, languageKey]);

    return (
        <>
            <div className="wrapper">
                {(startApplication && languagesLoaded) || devModeOn ? (
                    <>
                        <nav className={`main-header navbar navbar-expand navbar-white navbar-light ${applications && applications.length == 1 ? 'ml-0' : ''}`}>
                            {applications && applications.length > 1 ? (
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link" data-widget="pushmenu" role="button">
                                                <i className="fas fa-bars"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="navbar-nav">
                                        <li className="nav-item d-done d-sm-inline-block">
                                            <div className="">{currentApplicationName}</div>
                                        </li>
                                    </ul>
                                </>
                            ) : (
                                <div className="user-panel d-flex">
                                    <Link to="/benit/profile">
                                        <div className="image">
                                            <Avatar name={firstName + ' ' + lastName} round=".3rem" size="32" />
                                        </div>
                                    </Link>
                                    <Link to="/benit/profile">
                                        <div className="info">
                                            <a href="#" className="d-block text-dark">
                                                {firstName} {lastName}
                                            </a>
                                        </div>
                                    </Link>
                                </div>
                            )}
                            <ul className="navbar-nav ml-auto">
                                {appSpecificNavbarComponents && appSpecificNavbarComponents.length > 0 && appSpecificNavbarComponents.map((component) => component)}
                                <OrganizationPicker />
                                {userLanguages && userLanguages.length > 1 ? <LanguagePicker /> : null}
                                {/* <li className="nav-item">
                                    <a className="nav-link" style={{ paddingTop: '3px' }} onClick={(e) => benit_central_set_help_activated(!helpActivated)} href="#" role="button">
                                        <button
                                            type="button"
                                            className={`btn btn-block ${helpActivated ? 'btn-danger' : 'btn-info'} btn-sm`}
                                            style={{ WebkitBoxShadow: '0 0 5 #c1c1c1', boxShadow: '0 0 5 #c1c1c1' }}
                                            aria-pressed="false"
                                        >
                                            {helpActivated ? 'Close Help' : <i className="far fa-question-circle"></i>}
                                        </button>
                                    </a>
                                </li> */}
                                <li className="nav-item">
                                    <a onClick={benit_auth_logoutUser} className="nav-link" style={{ cursor: 'pointer' }}>
                                        <i className="nav-icon fas fa-sign-out-alt"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ display: applications && applications.length == 1 ? 'none' : 'null' }}>
                            <div className="sidebar" style={{ marginTop: '0px' }}>
                                <div className="user-panel mt-2 pb-2 mb-3 d-flex">
                                    <Link to="/benit/profile">
                                        <div className="image">
                                            <Avatar name={firstName + ' ' + lastName} round=".3rem" size="32" />
                                        </div>
                                    </Link>
                                    <Link to="/benit/profile">
                                        <div className="info">
                                            <a href="#" className="d-block">
                                                {firstName} {lastName}
                                            </a>
                                        </div>
                                    </Link>
                                </div>
                                <nav className="mt-2">
                                    <ul className="nav nav-pills nav-sidebar flex-column nav-flat" data-widget="treeview" role="menu" data-accordion="false">
                                        {applications
                                            ? applications.map((application) => {
                                                  return (
                                                      <li className={`nav-item`}>
                                                          <Link
                                                              to={application.path}
                                                              className={`nav-link ${
                                                                  window.location.pathname.startsWith(application.path) ||
                                                                  (startApplication &&
                                                                      typeof startApplication !== 'string' &&
                                                                      startApplication.path === application.path &&
                                                                      window.location.pathname === '/')
                                                                      ? 'active'
                                                                      : ''
                                                              }`}
                                                              onClick={closeSidebar}
                                                          >
                                                              <i className={`nav-icon ${application.icon}`}></i>
                                                              <p>{mlfv(application.name)}</p>
                                                          </Link>
                                                      </li>
                                                  );
                                              })
                                            : null}
                                    </ul>
                                </nav>
                            </div>
                        </aside>
                        <div className={`${applications && applications.length == 1 ? 'ml-0' : ''} content-wrapper`} style={{ minHeight: '0' }}>
                            <section className="content p-0">
                                <div className="container-fluid">
                                    <ErrorAlerts />
                                    {/* <Route
                                        path={
                                            startApplication &&
                                            typeof startApplication !== 'string' &&
                                            startApplication.path === '/benit/central-configuration/organization' &&
                                            window.location.pathname === '/'
                                                ? ['/', '/benit/central-configuration/organization']
                                                : ['/benit/central-configuration/organization']
                                        }
                                        exact
                                        component={OrganizationApp}
                                    /> */}
                                    <Route path="/benit/central-configuration/organization" exact component={OrganizationApp} />
                                    <Route path="/benit/central-configuration/service" exact component={ServiceApp} />
                                    <Route path="/benit/organization-configuration/users" exact component={UserApp} />
                                    <Route path="/benit/profile" exact component={ProfileApp} />
                                    <Route path="/benit/assistant" exact component={AssistantApp} />
                                    <Route path="/benit/accounting/configuration" exact component={AccountingConfigurationApp} />
                                    <Route path="/benit/accounting/invoices" exact component={InvoiceApp} />
                                    <Route exact path="/benit/accounting/invoices/:id" render={({ match }) => <InvoiceDetailApp invoiceId={String(match.params.id)} />} />
                                    <Route path="/benit/accounting/expenses" exact component={ExpensesApp} />
                                    <Route exact path="/benit/accounting/expenses/:id" render={({ match }) => <ExpensesDetailApp expenseId={String(match.params.id)} />} />

                                    {accountingMasterDataAppsConfiguration.map((configuration: any) => (
                                        <>
                                            <Route
                                                path={`/benit/accounting/${configuration.object}`}
                                                exact
                                                render={({ match }) => (
                                                    <MasterDataApp configuration={configuration} generateMockData={accountingGenerateMockData} navigationPath={'/benit/accounting/'} />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/benit/accounting/${configuration.object}/:id`}
                                                render={({ match }) => (
                                                    <MasterDataDetailApp
                                                        itemId={String(match.params.id)}
                                                        configuration={configuration}
                                                        generateMockData={accountingGenerateMockData}
                                                        navigationPath={'/benit/accounting/'}
                                                    />
                                                )}
                                            />
                                        </>
                                    ))}

                                    <Route path="/benit/accounting/time-tracking" exact component={TimeTrackingApp} />

                                    <Route path="/benit/care/sis" exact component={SISAssistantApp} />

                                    {propertyManagementMasterDataAppsConfiguration.map((configuration: any) => (
                                        <>
                                            <Route
                                                path={`/benit/property-management/${configuration.object}`}
                                                exact
                                                render={({ match }) => (
                                                    <MasterDataApp configuration={configuration} generateMockData={propertyManagementGenerateMockData} navigationPath={'/benit/property-management/'} />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={`/benit/property-management/${configuration.object}/:id`}
                                                render={({ match }) => (
                                                    <MasterDataDetailApp
                                                        itemId={String(match.params.id)}
                                                        configuration={configuration}
                                                        generateMockData={propertyManagementGenerateMockData}
                                                        navigationPath={'/benit/property-management/'}
                                                    />
                                                )}
                                            />
                                        </>
                                    ))}

                                    <Route path="/ri/property-management/users-owner" exact component={UserEigentuemerApp} />
                                    <Route path="/ri/property-management/transactions" exact component={TransactionApp} />
                                    <Route path="/ri/property-management/refresh-data" exact component={DataRefreshApp} />
                                    <Route path="/ri/property-management/excel-download" exact component={ExcelDownloadApp} />
                                    <Route path="/ri/property-management/report" exact component={ReportApp} />
                                    <Route path="/ri/property-management/configuration" exact component={ConfigurationApp} />
                                    <Route path="/ri/property-management/weg" exact component={WegApp} />
                                    <Route path="/ri/property-management/belege" exact component={BelegeApp} />
                                    <Route path="/ri/property-management/analytics" exact component={AnalyticsApp} />
                                    <Route path="/ri/real-estate/configuration" exact component={RIRealEstateConfigurationApp} />
                                    <Route path="/ri/real-estate/objects" exact component={RIRealEstateObjectsApp} />
                                    <Route exact path="/ri/real-estate/objects/:id" render={({ match }) => <RIRealEstateObjectsDetailApp itemId={String(match.params.id)} />} />
                                    <Route path="/ri/real-estate/homepage" exact component={RIRealEstateHomepageApp} />
                                    <Route path="/benit/real-estate/objects" exact component={BenitRealEstateObjectsApp} />
                                </div>
                            </section>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default MainWrapper;
