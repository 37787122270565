import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import { useHistory } from 'react-router-dom';

const TimeTrackingApp = () => {
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState('');

    const { t, i18n } = useTranslation(['general', 'benit-accounting']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { benit_accounting_get_expenses, benit_accounting_get_expense_document } = useActions();

    const { expenses, getExpensesPending } = useTypedSelector((state) => state.benitAccountingReducer);

    return (
        <div className="pt-2">
            <div className="col-xl-3 mt-2">
                <div className="card card-primary card-outline h-100 ">
                    <div className="card-header">Test</div>
                    <div className="card-body box-profile">
                        <div>
                            <div>
                                {' '}
                                <p className="text-muted">test</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-outline-primary btn-block" onClick={(e) => null}>
                            <b>{t('general:edit')}</b>
                        </button>

                        <button className="btn btn-outline-info btn-block" onClick={(e) => null}>
                            <b>{t('benit-accounting:download_pdf')}</b>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimeTrackingApp;
