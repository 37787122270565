import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useActions } from '../../hooks/useActions';
import useMultiLanguageFieldValue from '../../hooks/useMultiLanguageFieldValue';
import 'react-tabulator/lib/styles.css';
// import 'react-tabulator/lib/css/tabulator_bulma.min.css'; // theme
// import 'react-tabulator/lib/css/tabulator_bootstrap4.min.css'; // theme
// import 'react-tabulator/lib/css/tabulator_semanticui.min.css'; // theme
import 'react-tabulator/lib/css/tabulator_materialize.min.css'; // theme
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ColumnDefinition, ReactTabulator } from 'react-tabulator';
import styled from 'styled-components';
import { Faker, de } from '@faker-js/faker';
import { useHistory } from 'react-router-dom';

const StyledTabulator = styled(ReactTabulator)`
    background-color: rgb(244, 246, 249) !important;

    .tabulator .tabulator-header .tabulator-col .tabulator-header-contents .tabulator-frozen-rows-holder .tabulator-tableholder {
        background-color: rgb(244, 246, 249) !important;
    }
    .tabulator-header {
        background-color: rgb(244, 246, 249) !important;
        height: 50px;
    }
`;

export const MasterDataApp = ({ configuration, generateMockData, navigationPath }: any) => {
    const history = useHistory();
    const [devModeOn, setDevModeOn] = useState<boolean>(process.env.REACT_APP_DEV_MODE_ON === 'true');
    const [searchTerm, setSearchTerm] = useState('');
    const [rawData, setRawData] = useState<any>(null);
    const [data, setData] = useState<any>(null);

    const { t } = useTranslation(['general', 'benit-property-management', 'benit-accounting', 'benit-central-configuration']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { benit_central_configuration_get_services, benit_central_configuration_delete_service } = useActions();

    const { services, servicesLoading, servicesDidInvalidate } = useTypedSelector((state) => state.benitCentralConfigurationReducer);

    useEffect(() => {
        if (devModeOn) {
            setRawData(generateMockData({ object: configuration.object, numberOfRows: 200 })[configuration.object]);
        }

        const style = document.createElement('style');
        style.innerHTML = `
      .tabulator .tabulator-header .tabulator-col {
        background-color: rgb(244, 246, 249) !important;
      }
    `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    useEffect(() => {
        if (rawData) {
            configuration.columns.forEach((column: any) => {
                if (column.type === 'date') {
                    rawData.forEach((row: any) => {
                        row[column.field] = new Date(row[column.field]).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
                    });
                }
                if (column.type === 'currency') {
                    rawData.forEach((row: any) => {
                        row[column.field] = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(row[column.field]);
                    });
                }
            });
            setData(rawData);
        }
    }, [rawData]);

    function deepSearch(obj: any, searchTerm: string): boolean {
        if (obj === null || obj === undefined) return false;
        if (typeof obj !== 'object') {
            return obj.toString().toLowerCase().includes(searchTerm.toLowerCase());
        }
        return Object.values(obj).some((value: any) => deepSearch(value, searchTerm));
    }

    useEffect(() => {
        if (searchTerm && searchTerm !== '') {
            //filter should apply to all fields in data, including nested fields
            setData(rawData.filter((row: any) => deepSearch(row, searchTerm)));
        } else {
            setData(rawData);
        }
    }, [searchTerm]);

    return (
        <>
            <div className="row">
                <button
                    onClick={(e: any) => history.push(`${navigationPath}${configuration.object}/new`)}
                    style={{ fontSize: '18px', height: '45px' }}
                    type="button"
                    className="btn btn-outline-dark m-1 mt-2 ml-2 mr-2 w-100"
                >
                    {t(configuration.newButtonCaption)}
                </button>
            </div>
            <div className="row">
                <div className="input-group m-1 ml-2 mr-2">
                    <input
                        style={{ fontSize: '18px', height: '45px' }}
                        onInput={(e) => setSearchTerm((e.target as HTMLInputElement).value)}
                        type="text"
                        placeholder={`${t('general:search')}...`}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row">
                <StyledTabulator
                    data={data}
                    columns={configuration.columns.map((column: any) => ({ ...column, title: t(column.title) }))}
                    events={{
                        rowClick: (e: any, row: any) => {
                            history.push(`${navigationPath}${configuration.object}/${row.getData().id}`);
                        },
                    }}
                    options={{
                        columnDefaults: {
                            resizable: true,
                        },
                        rowHeader: { formatter: 'responsiveCollapse', width: 30, minWidth: 30, hozAlign: 'left', resizable: false, headerSort: false },
                        responsiveLayout: 'hide',
                        layout: 'fitColumns',
                        movableColumns: true,
                        // pagination: 'local',
                        // paginationSize: 6,
                        initialSort: configuration.initialSort,
                        headerSortElement: function (column: any, dir: any) {
                            //column - column component for current column
                            //dir - current sort direction ("asc", "desc", "none")

                            switch (dir) {
                                case 'asc':
                                    return "<i class='fas fa-sort-up'>";
                                    break;
                                case 'desc':
                                    return "<i class='fas fa-sort-down'>";
                                    break;
                                default:
                                    return '';
                            }
                        },
                        langs: {
                            'fr-fr': {
                                pagination: {
                                    first: 'Premier',
                                    first_title: 'Première Page',
                                    last: 'Dernier',
                                    last_title: 'Dernière Page',
                                    prev: 'Précédent',
                                    prev_title: 'Page Précédente',
                                    next: 'Suivant',
                                    next_title: 'Page Suivante',
                                    all: 'Toute',
                                },
                            },
                            'de-de': {
                                pagination: {
                                    first: 'Erste',
                                    first_title: 'Erste Seite',
                                    last: 'Letzte',
                                    last_title: 'Letzte Seite',
                                    prev: 'Vorige',
                                    prev_title: 'Vorige Seite',
                                    next: 'Nächste',
                                    next_title: 'Nächste Seite',
                                    all: 'Alle',
                                },
                            },
                        },
                    }}
                />
            </div>
        </>
    );
};
