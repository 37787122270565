export const propertyManagementMasterDataAppsConfiguration: any = [
    {
        object: 'owner',
        newButtonCaption: 'benit-property-management:add_new_owner',
        initialSort: [{ column: 'lastName', dir: 'asc' }],
        columns: [
            { title: 'general:full_name', field: 'fullName', hozAlign: 'left', minWidth: 200, responsive: 0 },
            { title: 'general:first_name', field: 'firstName', responsive: 20, hozAlign: 'left', sorter: 'number', minWidth: 150 },
            { title: 'general:last_name', field: 'lastName', responsive: 19, hozAlign: 'left', minWidth: 150 },
            { title: 'general:telephone_number', field: 'telephoneNumber', hozAlign: 'left', minWidth: 180 },
            { title: 'general:email', field: 'email', hozAlign: 'left', minWidth: 180 },
            { title: 'general:street', field: 'street', responsive: 15, minWidth: 250 },
            { title: 'general:number', field: 'streetNumber', responsive: 15, hozAlign: 'left', minWidth: 120 },
            { title: 'general:postcode', field: 'postcode', responsive: 14, hozAlign: 'left', minWidth: 150 },
            { title: 'general:city', field: 'city', responsive: 14, hozAlign: 'left', minWidth: 200 },
            { title: 'general:contract_from', field: 'contractFrom', hozAlign: 'left', minWidth: 100, type: 'date' },
            { title: 'general:contract_to', field: 'contractTo', hozAlign: 'left', minWidth: 130, type: 'date' },
            { title: 'general:iban', field: 'iban', hozAlign: 'left', minWidth: 280 },
            { title: 'general:bic', field: 'bic', hozAlign: 'left', minWidth: 150 },
        ],
    },
    {
        object: 'property',
        newButtonCaption: 'benit-property-management:add_new_property',
        initialSort: [
            { column: 'name', dir: 'asc' },
            { column: 'owner.fullName', dir: 'asc' },
        ],
        columns: [
            { title: 'benit-property-management:owner', field: 'owner.fullName', hozAlign: 'left', minWidth: 200, responsive: 0, lookup: { object: 'owner', displayField: 'fullName' } },
            { title: 'general:name', field: 'name', responsive: 1, minWidth: 250 },
            { title: 'general:street', field: 'street', responsive: 15, minWidth: 250 },
            { title: 'general:number', field: 'streetNumber', responsive: 15, hozAlign: 'left', minWidth: 120 },
            { title: 'general:postcode', field: 'postcode', responsive: 14, hozAlign: 'left', minWidth: 150 },
            { title: 'general:city', field: 'city', responsive: 14, hozAlign: 'left', minWidth: 200 },
            { title: 'benit-property-management:parcel', field: 'parcel', hozAlign: 'left', minWidth: 100 },
            { title: 'benit-property-management:total_area', field: 'totalArea', hozAlign: 'right', minWidth: 100 },
            { title: 'benit-property-management:number_rental_units', field: 'numberRentalUnits', hozAlign: 'right', minWidth: 100 },
            { title: 'general:status', field: 'propertyStatus', hozAlign: 'left', minWidth: 100 },
        ],
    },
    {
        object: 'rentalUnit',
        newButtonCaption: 'benit-property-management:add_new_rental_unit',
        initialSort: [
            { column: 'location', dir: 'asc' },
            { column: 'property.name', dir: 'asc' },
            { column: 'owner.fullName', dir: 'asc' },
        ],
        columns: [
            { title: 'benit-property-management:owner', field: 'owner.fullName', hozAlign: 'left', minWidth: 200, responsive: 0, lookup: { object: 'owner', displayField: 'fullName' } },
            {
                title: 'benit-property-management:property',
                field: 'property.name',
                hozAlign: 'left',
                minWidth: 400,
                responsive: 0,
                lookup: { object: 'property', displayField: 'name', parent: 'owner' },
            },
            { title: 'benit-property-management:appartment_number', field: 'appartmentNumber', hozAlign: 'left', minWidth: 80 },
            { title: 'general:description', field: 'description', minWidth: 250 },
            { title: 'benit-property-management:location', field: 'location', responsive: 15, hozAlign: 'left', minWidth: 120 },
            { title: 'general:type', field: 'type', responsive: 14, hozAlign: 'left', minWidth: 100 },
            { title: 'benit-property-management:area', field: 'area', responsive: 14, hozAlign: 'left', minWidth: 80 },
        ],
    },
    {
        object: 'tenant',
        newButtonCaption: 'benit-property-management:add_new_tenant',
        initialSort: [
            { column: 'rentalUnit.description', dir: 'asc' },
            { column: 'property.name', dir: 'asc' },
            { column: 'owner.fullName', dir: 'asc' },
        ],
        columns: [
            { title: 'benit-property-management:owner', field: 'owner.fullName', hozAlign: 'left', minWidth: 200, responsive: 0, lookup: { object: 'owner', displayField: 'fullName' } },
            {
                title: 'benit-property-management:property',
                field: 'property.name',
                hozAlign: 'left',
                minWidth: 400,
                responsive: 0,
                lookup: { object: 'property', displayField: 'name', parent: 'owner' },
            },
            {
                title: 'benit-property-management:rental_unit',
                field: 'rentalUnit.description',
                hozAlign: 'left',
                minWidth: 250,
                responsive: 0,
                lookup: { object: 'rentalUnit', displayField: 'description', parent: 'property' },
            },
            { title: 'general:salutation', field: 'salutation', minWidth: 100 },
            { title: 'general:title', field: 'title', minWidth: 100 },
            { title: 'general:full_name', field: 'fullName', hozAlign: 'left', minWidth: 200, responsive: 0 },
            { title: 'general:first_name', field: 'firstName', hozAlign: 'left', sorter: 'number', minWidth: 150 },
            { title: 'general:last_name', field: 'lastName', hozAlign: 'left', minWidth: 150 },
            { title: 'general:telephone_number', field: 'telephoneNumber', hozAlign: 'left', minWidth: 180 },
            { title: 'general:street', field: 'street', responsive: 15, minWidth: 250 },
            { title: 'general:number', field: 'streetNumber', responsive: 15, hozAlign: 'left', minWidth: 120 },
            { title: 'general:postcode', field: 'postcode', responsive: 14, hozAlign: 'left', minWidth: 150 },
            { title: 'general:city', field: 'city', responsive: 14, hozAlign: 'left', minWidth: 200 },
            { title: 'benit-property-management:type_of_use', field: 'typeOfUse', responsive: 14, hozAlign: 'left', minWidth: 150 },
            { title: 'general:iban', field: 'iban', hozAlign: 'left', minWidth: 280 },
            { title: 'general:bic', field: 'bic', hozAlign: 'left', minWidth: 150 },
        ],
    },
    {
        object: 'rentalSecurityDepositAccount',
        newButtonCaption: 'benit-property-management:add_new_rental_security_deposit_account',
        initialSort: [
            { column: 'tenant.fullName', dir: 'asc' },
            { column: 'rentalUnit.description', dir: 'asc' },
            { column: 'property.name', dir: 'asc' },
            { column: 'owner.fullName', dir: 'asc' },
        ],
        columns: [
            { title: 'benit-property-management:owner', field: 'owner.fullName', hozAlign: 'left', minWidth: 200, responsive: 0, lookup: { object: 'owner', displayField: 'fullName' } },
            {
                title: 'benit-property-management:property',
                field: 'property.name',
                hozAlign: 'left',
                minWidth: 400,
                responsive: 0,
                lookup: { object: 'property', displayField: 'name', parent: 'owner' },
            },
            {
                title: 'benit-property-management:rental_unit',
                field: 'rentalUnit.description',
                hozAlign: 'left',
                minWidth: 250,
                responsive: 0,
                lookup: { object: 'rentalUnit', displayField: 'description', parent: 'property' },
            },
            {
                title: 'benit-property-management:tenant',
                field: 'tenant.fullName',
                hozAlign: 'left',
                minWidth: 250,
                responsive: 0,
                lookup: { object: 'tenant', displayField: 'fullName', parent: 'rentalUnit' },
            },
            { title: 'general:iban', field: 'iban', hozAlign: 'left', minWidth: 280 },
            { title: 'general:bic', field: 'bic', hozAlign: 'left', minWidth: 150 },
            { title: 'benit-property-management:account_type', field: 'accountType', hozAlign: 'left', minWidth: 150 },
            { title: 'general:status', field: 'status', hozAlign: 'left', minWidth: 150 },
            { title: 'benit-property-management:amount', field: 'amount', hozAlign: 'left', minWidth: 150, type: 'currency' },
            { title: 'benit-property-management:last_change', field: 'lastChange', hozAlign: 'left', minWidth: 150, type: 'date' },
        ],
    },
    {
        object: 'costStructure',
        newButtonCaption: 'benit-property-management:add_new_cost_structure',
        initialSort: [
            { column: 'tenant.fullName', dir: 'asc' },
            { column: 'rentalUnit.description', dir: 'asc' },
            { column: 'property.name', dir: 'asc' },
            { column: 'owner.fullName', dir: 'asc' },
        ],
        columns: [
            { title: 'benit-property-management:owner', field: 'owner.fullName', hozAlign: 'left', minWidth: 200, responsive: 0, lookup: { object: 'owner', displayField: 'fullName' } },
            {
                title: 'benit-property-management:property',
                field: 'property.name',
                hozAlign: 'left',
                minWidth: 400,
                responsive: 0,
                lookup: { object: 'property', displayField: 'name', parent: 'owner' },
            },
            {
                title: 'benit-property-management:rental_unit',
                field: 'rentalUnit.description',
                hozAlign: 'left',
                minWidth: 350,
                responsive: 0,
                lookup: { object: 'rentalUnit', displayField: 'description', parent: 'property' },
            },
            {
                title: 'benit-property-management:tenant',
                field: 'tenant.fullName',
                hozAlign: 'left',
                minWidth: 250,
                responsive: 0,
                lookup: { object: 'tenant', displayField: 'fullName', parent: 'rentalUnit' },
            },
            { title: 'benit-property-management:rent_from', field: 'rentFrom', minWidth: 150, type: 'date' },
            { title: 'benit-property-management:rent_to', field: 'rentTo', minWidth: 150, type: 'date' },
            { title: 'benit-property-management:cold_rent', field: 'coldRent', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:operating_cost', field: 'operatingCost', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:heating_cost', field: 'heatingCost', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:car_monthly', field: 'carMonthly', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:garden_monthly', field: 'gardenMonthly', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:electricity', field: 'electricity', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:antenna', field: 'antenna', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:vat', field: 'vat', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:car_annual', field: 'carAnnual', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:garden_annual', field: 'gardenAnnual', minWidth: 100, type: 'currency' },
        ],
    },
    {
        object: 'serviceProvider',
        newButtonCaption: 'benit-property-management:add_new_service_provider',
        initialSort: [{ column: 'company', dir: 'asc' }],
        columns: [
            { title: 'general:service_provider', field: 'company', hozAlign: 'left', minWidth: 200, responsive: 0 },
            { title: 'general:full_name', field: 'fullName', hozAlign: 'left', minWidth: 200, responsive: 0 },
            { title: 'general:first_name', field: 'firstName', responsive: 20, hozAlign: 'left', sorter: 'number', minWidth: 150 },
            { title: 'general:last_name', field: 'lastName', responsive: 19, hozAlign: 'left', minWidth: 150 },
            { title: 'general:telephone_number', field: 'telephoneNumber', hozAlign: 'left', minWidth: 180 },
            { title: 'general:email', field: 'email', hozAlign: 'left', minWidth: 250 },
            { title: 'general:street', field: 'street', responsive: 15, minWidth: 250 },
            { title: 'general:number', field: 'streetNumber', responsive: 15, hozAlign: 'left', minWidth: 120 },
            { title: 'general:postcode', field: 'postcode', responsive: 14, hozAlign: 'left', minWidth: 150 },
            { title: 'general:city', field: 'city', responsive: 14, hozAlign: 'left', minWidth: 200 },
            { title: 'general:iban', field: 'iban', hozAlign: 'left', minWidth: 280 },
            { title: 'general:bic', field: 'bic', hozAlign: 'left', minWidth: 150 },
        ],
    },
    {
        object: 'costType',
        newButtonCaption: 'benit-property-management:add_new_cost_type',
        initialSort: [{ column: 'shortName', dir: 'asc' }],
        columns: [
            { title: 'general:short_name', field: 'shortName', hozAlign: 'left', minWidth: 200, responsive: 0 },
            { title: 'general:long_name', field: 'longName', hozAlign: 'left', minWidth: 200, responsive: 0 },
            { title: 'benit-property-management:income', field: 'income', hozAlign: 'left', minWidth: 200 },
            { title: 'benit-property-management:outcome', field: 'outcome', hozAlign: 'left', minWidth: 200 },
            { title: 'benit-property-management:tenant_binding', field: 'tenantBinding', hozAlign: 'left', minWidth: 200 },
            { title: 'benit-property-management:service_provider', field: 'serviceProvider', hozAlign: 'left', minWidth: 200 },
            { title: 'benit-property-management:transferable', field: 'transferable', hozAlign: 'left', minWidth: 200 },
            { title: 'benit-property-management:object_tenant_binding', field: 'objectTenantBinding', hozAlign: 'left', minWidth: 200 },
            { title: 'benit-property-management:category', field: 'category', hozAlign: 'left', minWidth: 400 },
            { title: 'benit-property-management:bka_cost_type', field: 'bkaCostType', hozAlign: 'left', minWidth: 200 },
        ],
    },
    {
        object: 'administrativeCost',
        newButtonCaption: 'benit-property-management:add_new_administrative_cost',
        initialSort: [
            { column: 'property.name', dir: 'asc' },
            { column: 'owner.fullName', dir: 'asc' },
        ],
        columns: [
            { title: 'benit-property-management:owner', field: 'owner.fullName', hozAlign: 'left', minWidth: 200, responsive: 0, lookup: { object: 'owner', displayField: 'fullName' } },
            {
                title: 'benit-property-management:property',
                field: 'property.name',
                hozAlign: 'left',
                minWidth: 400,
                responsive: 0,
                lookup: { object: 'property', displayField: 'name', parent: 'owner' },
            },
            { title: 'general:fromDate', field: 'fromDate', hozAlign: 'left', minWidth: 250, type: 'date' },
            { title: 'general:toDate', field: 'toDate', hozAlign: 'left', minWidth: 250, type: 'date' },
            { title: 'benit-property-management:amount_residential_units', field: 'amountResidentialUnits', responsive: 14, hozAlign: 'left', minWidth: 100 },
            { title: 'benit-property-management:cost_residential_unit', field: 'costResidentialUnit', responsive: 14, hozAlign: 'left', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:amount_business_units', field: 'amountBusinessUnits', responsive: 14, hozAlign: 'left', type: 'currency' },
            { title: 'benit-property-management:cost_business_unit', field: 'costBusinessUnit', responsive: 14, hozAlign: 'left', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:amount_other_units', field: 'amountOtherUnits', responsive: 14, hozAlign: 'left', minWidth: 100 },
            { title: 'benit-property-management:cost_other_unit', field: 'costOtherUnit', responsive: 14, hozAlign: 'left', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:amount_expenses', field: 'amountExpenses', responsive: 14, hozAlign: 'left', minWidth: 100 },
            { title: 'benit-property-management:cost_expenses', field: 'costExpenses', responsive: 14, hozAlign: 'left', minWidth: 100, type: 'currency' },
        ],
    },
    {
        object: 'homeownersAssociationCostStructure',
        newButtonCaption: 'benit-property-management:add_new_homeowners_association_cost_structure',
        initialSort: [
            { column: 'rentalUnit.description', dir: 'asc' },
            { column: 'property.name', dir: 'asc' },
            { column: 'owner.fullName', dir: 'asc' },
        ],
        columns: [
            { title: 'benit-property-management:owner', field: 'owner.fullName', hozAlign: 'left', minWidth: 200, responsive: 0, lookup: { object: 'owner', displayField: 'fullName' } },
            {
                title: 'benit-property-management:property',
                field: 'property.name',
                hozAlign: 'left',
                minWidth: 400,
                responsive: 0,
                lookup: { object: 'property', displayField: 'name', parent: 'owner' },
            },
            {
                title: 'benit-property-management:rental_unit',
                field: 'rentalUnit.description',
                hozAlign: 'left',
                minWidth: 250,
                responsive: 0,
                lookup: { object: 'rentalUnit', displayField: 'description', parent: 'property' },
            },
            { title: 'general:fromDate', field: 'fromDate', hozAlign: 'left', minWidth: 250, type: 'date' },
            { title: 'general:toDate', field: 'toDate', hozAlign: 'left', minWidth: 250, type: 'date' },
            { title: 'benit-property-management:housing_contribution', field: 'housingContribution', hozAlign: 'left', minWidth: 100, type: 'currency' },
            { title: 'benit-property-management:maintenance_reserve', field: 'maintenanceReserve', hozAlign: 'left', minWidth: 100, type: 'currency' },
            { title: 'general:sum', field: 'sum', hozAlign: 'left', minWidth: 100, type: 'currency' },
            { title: 'general:iban', field: 'iban', hozAlign: 'left', minWidth: 280 },
            { title: 'general:bic', field: 'bic', hozAlign: 'left', minWidth: 150 },
        ],
    },
];
