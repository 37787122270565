import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useActions } from '../../hooks/useActions';
import useMultiLanguageFieldValue from '../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../MultiLanguageInput/MultiLanguageInput';
import 'react-tabulator/lib/styles.css';
// import 'react-tabulator/lib/css/tabulator_bulma.min.css'; // theme
// import 'react-tabulator/lib/css/tabulator_bootstrap4.min.css'; // theme
// import 'react-tabulator/lib/css/tabulator_semanticui.min.css'; // theme
import 'react-tabulator/lib/css/tabulator_materialize.min.css'; // theme
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ColumnDefinition, ReactTabulator } from 'react-tabulator';
import styled from 'styled-components';
import { Faker, de } from '@faker-js/faker';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './MasterDataDetailApp.css';

const StyledTabulator = styled(ReactTabulator)`
    background-color: rgb(244, 246, 249) !important;

    .tabulator .tabulator-header .tabulator-col .tabulator-header-contents .tabulator-frozen-rows-holder .tabulator-tableholder {
        background-color: rgb(244, 246, 249) !important;
    }
    .tabulator-header {
        background-color: rgb(244, 246, 249) !important;
        height: 50px;
    }
`;

export const MasterDataDetailApp = ({ configuration, itemId, generateMockData, navigationPath }: any) => {
    const history = useHistory();
    const [devModeOn, setDevModeOn] = useState<boolean>(process.env.REACT_APP_DEV_MODE_ON === 'true');
    const [data, setData] = useState<any>({});
    const [lookupData, setLookupData] = useState<any>(null);
    const [isNewObject, setIsNewObject] = useState<boolean>(false);
    const [startDate, setStartDate] = useState(new Date());

    const { t } = useTranslation(['general', 'benit-property-management', 'benit-accounting', 'benit-central-configuration']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { benit_central_configuration_get_services, benit_central_configuration_delete_service } = useActions();

    const { services, servicesLoading, servicesDidInvalidate } = useTypedSelector((state) => state.benitCentralConfigurationReducer);

    useEffect(() => {
        if (devModeOn) {
            const dataTmp = generateMockData({ object: configuration.object, numberOfRows: 1000 });
            if (itemId !== 'new') {
                setData(dataTmp[configuration.object][0]);
            }
            let lookupDataTmp: any[] = [];
            for (let column of configuration.columns) {
                if (column.lookup?.object) {
                    lookupDataTmp.push({
                        object: column.lookup.object,
                        data: dataTmp[column.lookup.object],
                    });
                }
            }
            setLookupData(lookupDataTmp);
        }
        if (itemId === 'new') {
            setIsNewObject(true);
        }
    }, []);

    useEffect(() => {
        console.log(data);
    }, [data]);

    const setLookupObject = (data: any, object: string, objectId: any) => {
        let dataTmp = {
            ...data,
            [object]: objectId === 0 ? null : lookupData?.find((lookup: any) => lookup && lookup.object === object)?.data.find((lookupItem: any) => lookupItem && lookupItem.id === objectId),
        };

        dataTmp = setChildLookupObject(dataTmp, object, objectId);

        return dataTmp;
    };

    const setChildLookupObject = (data: any, object: string, objectId: any) => {
        let dataTmp = {
            ...data,
        };

        for (let column of configuration.columns) {
            if (column.lookup?.parent === object) {
                dataTmp = {
                    ...dataTmp,
                    [column.lookup.object]: data[object] === null ? null : data[column.lookup.object]?.[object]?.id === objectId ? data[column.lookup.object] : null,
                };
                dataTmp = setChildLookupObject(dataTmp, column.lookup.object, dataTmp[column.lookup.object]?.id);
            }
        }

        return dataTmp;
    };

    return (
        <>
            {data &&
                configuration.columns.map((column: any, index: any) => {
                    return (
                        <div className="row" key={index}>
                            <div className="col-12 mt-4">
                                <label style={{ fontSize: '18px' }}>{t(column.title)}</label>
                                {column.lookup?.object && (
                                    <select
                                        className="form-control"
                                        style={{ fontSize: '18px', height: '45px' }}
                                        onChange={(e) => {
                                            setData(setLookupObject(data, column.lookup.object, e.target.value));
                                        }}
                                    >
                                        <option key={`null-${column.lookup.object}`} value={0} />
                                        {lookupData
                                            ?.find((lookup: any) => lookup.object === column.lookup.object)
                                            ?.data.filter(
                                                (lookupItem: any) => !column.lookup.parent || (data[column.lookup.parent] && lookupItem[column.lookup.parent].id === data[column.lookup.parent].id)
                                            )
                                            .map((lookupItem: any, index: any) => {
                                                return (
                                                    <option key={index} value={lookupItem.id} selected={data[column.lookup?.object] && lookupItem.id === data[column.lookup.object].id}>
                                                        {lookupItem[column.lookup.displayField]}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                )}
                                {!column.lookup?.object && !column.type && (
                                    <input
                                        style={{ fontSize: '18px', height: '45px' }}
                                        onInput={(e) => setData({ ...data, [column.field]: (e.target as HTMLInputElement).value })}
                                        autoFocus={index === 0}
                                        type="text"
                                        // placeholder={column.title}
                                        value={data[column.field] ?? ''}
                                        className="form-control"
                                    />
                                )}
                                {!column.lookup?.object && column.type === 'date' && (
                                    <div className=" w-100" style={{ fontSize: '18px', height: '45px' }}>
                                        <DatePicker
                                            className="form-control"
                                            wrapperClassName="datePicker"
                                            selected={data[column.field] ?? ''}
                                            onChange={(date: Date) => setData({ ...data, [column.field]: date })}
                                            dateFormat="dd.MM.yyyy"
                                        />
                                    </div>
                                )}
                                {!column.lookup?.object && column.type === 'currency' && (
                                    <div className=" w-100" style={{ fontSize: '18px', height: '45px' }}>
                                        <div className="input-group">
                                            <input
                                                style={{ fontSize: '18px', height: '45px' }}
                                                onInput={(e) => setData({ ...data, [column.field]: (e.target as HTMLInputElement).value })}
                                                autoFocus={index === 0}
                                                type="text"
                                                // placeholder={column.title}
                                                value={data[column.field] ?? ''}
                                                className="form-control text-right"
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">€</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            <div className="row mt-5">
                <button
                    onClick={(e: any) => history.push(`${navigationPath}${configuration.object}`)}
                    style={{ fontSize: '18px', height: '45px' }}
                    type="button"
                    className="btn btn-primary m-1 mt-2 ml-2 mr-2 w-100"
                >
                    {t('general:save')}
                </button>
            </div>
            <div className="row mt-2">
                <button
                    onClick={(e: any) => history.push(`${navigationPath}${configuration.object}`)}
                    style={{ fontSize: '18px', height: '45px' }}
                    type="button"
                    className="btn btn-danger m-1 mt-2 ml-2 mr-2 w-100"
                >
                    {t('general:delete')}
                </button>
            </div>
            <div className="row mt-2 mb-5">
                <button
                    onClick={(e: any) => history.push(`${navigationPath}${configuration.object}`)}
                    style={{ fontSize: '18px', height: '45px' }}
                    type="button"
                    className="btn btn-outline-dark m-1 mt-2 ml-2 mr-2 w-100"
                >
                    {t('general:cancel')}
                </button>
            </div>
        </>
    );
};
