import { Faker, de, en, en_GB, en_US } from '@faker-js/faker';

export const accountingGenerateMockData = ({ object, numberOfRows }: any) => {
    let data: any = {
        customer: [],
        project: [],
        service: [],
    };

    if (object === 'customer') {
        for (let i = 0; i < numberOfRows; i++) {
            const customFaker = new Faker({ locale: [de] });
            data.customer.push({
                id: customFaker.string.uuid(),
                name: customFaker.company.name(),
                telephoneNumber: customFaker.phone.number('+49 ### #######'),
                email: customFaker.internet.email(),
                street: customFaker.location.street(),
                streetNumber: customFaker.location.buildingNumber(),
                postcode: customFaker.location.zipCode(),
                city: customFaker.location.city(),
            });
        }
        return data;
    }

    if (object === 'project') {
        data.customer = accountingGenerateMockData({ object: 'customer', numberOfRows: Math.ceil(numberOfRows / 4) })['customer'];
        for (let i = 0; i < numberOfRows; i++) {
            const customFaker = new Faker({ locale: [de] });
            data.project.push({
                id: customFaker.string.uuid(),
                customer: data.customer[Math.floor(Math.random() * data.customer.length)],
                name: `Projekt "${customFaker.word.adjective()} ${customFaker.word.noun()}"`,
                projectStartDate: customFaker.date.recent(),
                projectEndDate: customFaker.date.future(),
            });
        }

        return data;
    }

    if (object === 'service') {
        for (let i = 0; i < numberOfRows; i++) {
            const customFaker = new Faker({ locale: [en] });
            data.service.push({
                id: customFaker.string.uuid(),
                name: `${customFaker.hacker.ingverb()} ${customFaker.hacker.adjective()}  ${customFaker.hacker.noun()}`,
                description: `${customFaker.word.verb()} ${customFaker.word.adjective()}  ${customFaker.word.noun()}`,
                amount: parseFloat(customFaker.helpers.fromRegExp(/[0-9]{2}.[0-9]{2}/)),
            });
        }

        return data;
    }

    return data;
};
