export const accountingMasterDataAppsConfiguration: any = [
    {
        object: 'customer',
        newButtonCaption: 'benit-accounting:add_new_customer',
        initialSort: [{ column: 'name', dir: 'asc' }],
        columns: [
            { title: 'general:name', field: 'name', hozAlign: 'left', minWidth: 200, responsive: 0 },
            { title: 'general:telephone_number', field: 'telephoneNumber', hozAlign: 'left', minWidth: 180 },
            { title: 'general:email', field: 'email', hozAlign: 'left', minWidth: 180 },
            { title: 'general:street', field: 'street', responsive: 15, minWidth: 250 },
            { title: 'general:number', field: 'streetNumber', responsive: 15, hozAlign: 'left', minWidth: 120 },
            { title: 'general:postcode', field: 'postcode', responsive: 14, hozAlign: 'left', minWidth: 150 },
            { title: 'general:city', field: 'city', responsive: 14, hozAlign: 'left', minWidth: 200 },
        ],
    },
    {
        object: 'project',
        newButtonCaption: 'benit-accounting:add_new_project',
        initialSort: [
            { column: 'customer.name', dir: 'asc' },
            { column: 'name', dir: 'asc' },
        ],
        columns: [
            { title: 'general:customer', field: 'customer.name', hozAlign: 'left', minWidth: 200, responsive: 0, lookup: { object: 'customer', displayField: 'name' } },
            { title: 'benit-accounting:project', field: 'name', responsive: 1, minWidth: 250 },
            { title: 'benit-accounting:project_start_date', field: 'projectStartDate', hozAlign: 'left', minWidth: 100, type: 'date' },
            { title: 'benit-accounting:project_end_date', field: 'projectEndDate', hozAlign: 'left', minWidth: 100, type: 'date' },
        ],
    },
    {
        object: 'service',
        newButtonCaption: 'benit-accounting:service',
        initialSort: [{ column: 'name', dir: 'asc' }],
        columns: [
            { title: 'benit-accounting:service', field: 'name', hozAlign: 'left', minWidth: 80 },
            { title: 'general:description', field: 'description', minWidth: 250 },
            { title: 'general:amount', field: 'amount', minWidth: 100, type: 'currency' },
        ],
    },
];
