import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainWrapper from './services/benit/central-ui/components/Container/MainWrapper';
import Login from './services/benit/auth/components/Login/Login';
import { useTypedSelector } from './hooks/useTypedSelector';
import { useActions } from './hooks/useActions';
import { LoadingAnimation } from './components/LoadingAnimation/LoadingAnimation';
import SetPassword from './services/benit/auth/components/SetPassword/SetPassword';

const Main = () => {
    const { signedIn, loading, chosenOrganization, loginRedirect } = useTypedSelector((state) => state.benitAuthReducer);
    const { benit_auth_getCurrentUser } = useActions();
    const [devModeOn, setDevModeOn] = useState<boolean>(process.env.REACT_APP_DEV_MODE_ON === 'true');

    useEffect(() => {
        benit_auth_getCurrentUser();
    }, []);

    const signedInComponent = () => {
        if (window.location.pathname.includes('/benit/auth/set-password/') && !loginRedirect) {
            return <SetPassword />;
        } else if ((signedIn && chosenOrganization) || devModeOn) return <MainWrapper devModeOn={devModeOn} />;
        else return <Login />;
    };

    return <div>{loading ? <LoadingAnimation /> : signedInComponent()}</div>;
};

export default Main;
